<template>
<div>
  <div class="Classification">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        :name="index + 1"
        v-for="(item, index) in list"
        :key="index"
      >
        <template slot="title">
          <div class="Classification_top">
            <div class="Classification_top_title">{{ item.title }}</div>
            <div class="Classification_top_action">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click.stop="editCat(item.id, item.title)"
                circle
              >
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="handleClose(item.id)"
                circle
              >
              </el-button>
            </div>
          </div>
        </template>
        <div class="Classification_main">
          <div class="Classification_main_item">
            <el-tag
              :key="tagKey"
              v-for="(tag, tagKey) in item.children"
              closable
              style="margin: 10px"
              :disable-transitions="false"
              @close="handleClose(tag.id)">
              <i
                @click.stop="editCat(tag.id, tag.title)"
                class="el-icon-edit Classification_main_itemTag">
              </i>
              {{ tag.title }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="item.inputVisible"
              v-model="item.inputValue"
              size="small"
              ref="saveTagInput"
              @change="handleInputConfirm(index, item.id, item.inputValue)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput(index)"
            >
              + New Cat
            </el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="Classification_footer">
      <i class="el-icon-circle-plus-outline" @click="addFirstLevelCat()"></i>
    </div>
  </div>

  <div class="meterial_classify">
    <div class="title">素材分类</div>
    <div class="classify_list">
      <el-tag :key="tag.id" v-for="tag in materialClassifyList" closable :disable-transitions="false" @click="editName(tag.id, tag.title)" @close="delMaterialClassify(tag.id)">
        {{tag.title}}
        <i class="el-icon-edit Classification_main_itemTag"></i>
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="addMaterialClassify"
        @blur="addMaterialClassify"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="newTag">+ 新分类</el-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1"],
      list: [],
      materialClassifyList: [],
      inputValue: '',
      inputVisible: false,
    };
  },
  created() {
    this.getCatList();
    this.getMaterialClassifyList();
  },
  methods: {
    // 获取分类参数
    getCatList() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let cat_list = res.data.data;
          cat_list.map((item) => {
            item.inputVisible = false;
            item.inputValue = "";
          });
          this.list = cat_list;
        }
      });
    },
    // 标签输入框状态切换
    showInput(index) {
      // 因为进行v-if状态切换的时候，输入框还没有渲染出来，就会导致获取不到ref
      new Promise((resolve, reject) => {
        this.list[index].inputVisible = true;
        resolve();
      }).then((result) => {
        this.$refs.saveTagInput[0].focus();
      });
    },
    // 添加分类
    handleInputConfirm(index, id, name) {
      if (name == "") {
        this.$message({
          showClose: true,
          message: "分类名称不能为空",
          type: "warning",
        });
        return;
      } else {
        this.$axios({
          url: this.$api + "/admin/ppt/handlescat",
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;",
          },
          data: {
            cat_name: name,
            parent_id: id,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
            this.list[index].inputVisible = false;
            this.list[index].inputValue = "";
            this.getCatList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    // 编辑分类
    editCat(id, title) {
      this.$prompt("请输入需要修改的分类名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: title,
      })
        .then(({ value }) => {
          if (value == "") {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "warning",
            });
            return;
          }
          this.$axios({
            url: this.$api + "/admin/ppt/handlescat",
            method: "PUT",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/json;charset=UTF-8;",
            },
            params: {
              cat_id: id,
            },
            data: {
              cat_name: value,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.getCatList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 删除分类标签
    handleClose(id) {
      this.$confirm("是确认否删除该分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.$api + "/admin/ppt/handlescat",
            method: "DELETE",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/json;charset=UTF-8;",
            },
            params: {
              cat_id: id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.getCatList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加一级分类
    addFirstLevelCat() {
      this.$prompt("请输入一级分类名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$axios({
            url: this.$api + "/admin/ppt/handlescat",
            method: "POST",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/json;charset=UTF-8;",
            },
            data: {
              cat_name: value,
              parent_id: 0,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.getCatList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    getMaterialClassifyList() { // 获取素材分类
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/catlist',
        method: 'GET',
      }).then(res => {
        this.materialClassifyList = res.data ? res.data : [];
      })
    },
    newTag() {  // 添加素材分类
      this.inputVisible = true;
    },
    addMaterialClassify() { // 提交素材分类
      this.$service.DEFAULT({
        url: '/admin/fodder/cathandler',
        method: 'POST',
        data: {
          parent_id: '',
          cat_name: this.inputValue,
        }
      }).then(res => {
        if (res.code == 200) {
          this.inputVisible = false;
          this.inputValue = '';
          this.getMaterialClassifyList();
        }
      })
    },
    delMaterialClassify(cat_id) { // 删除素材分类
      this.$confirm('将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$service.DEFAULT({
          url: '/admin/fodder/cathandler',
          method: 'DELETE',
          params: {
            cat_id,
          }
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getMaterialClassifyList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    editName(cat_id, cat_name) {  // 编辑素材分类
      this.$prompt('素材分类名称修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: cat_name,
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.$service.DEFAULT({
          url: '/admin/fodder/cathandler?cat_id=' + cat_id,
          method: 'PUT',
          data: {
            cat_name: value,
          }
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.getMaterialClassifyList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消修改'
        });       
      });
    },
  },
};
</script>

<style scoped>
.Classification {
  width: 70%;
  margin: 30px auto 0;
  padding: 25px;
  border-radius: 15px;
  background-color: #fff;
  box-sizing: border-box;
}
.Classification_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
}
.Classification_top_title {
  font-size: 24px;
}
.Classification_top_action {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Classification_main_item {
  padding: 20px;
  box-sizing: border-box;
}
.Classification_main_itemTag {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 100%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.Classification_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
}
.Classification_footer > i {
  font-size: 58px;
  color: #828282;
  cursor: pointer;
}

.meterial_classify {
  width: 70%;
  margin: 30px auto 0;
  padding: 25px;
  border-radius: 15px;
  background-color: #fff;
  box-sizing: border-box;
}
.meterial_classify .title {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 6px;
  border-bottom: 6px double #ededed;
}
.meterial_classify .classify_list {
  padding: 20px;
}
.meterial_classify .classify_list .el-tag {
  margin: 15px;
}
</style>